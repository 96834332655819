/// <reference path="../node_modules/@gower/utils/@types/index.d.ts" />
/// <reference path="../node_modules/@gower/utils/index.d.ts" />
import ReactDOM from 'react-dom/client';
import '@gower/react-shared-ui/import';
import '@gower/react-ui/import';
import '@gower/utils/extension';
import 'app/configure-sdk';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Router } from './app/router';
import theme from './app/theme';

import dayjs from "dayjs";
import utc from 'dayjs/plugin/timezone';
import timezone from 'dayjs/plugin/utc';
import 'dayjs/locale/es';
dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.locale( 'es' );
dayjs.tz.setDefault( "America/Argentina/Buenos_Aires" );


window['env'] = {
  ...process.env,
  ...window['env']
};

const root = ReactDOM.createRoot(
  document.getElementById( 'root' ) as HTMLElement
);

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router />
  </ThemeProvider>,
  // </React.StrictMode>
);

//if ( window.env.REACT_APP_WEB_VITALS === 'console' )
//  reportWebVitals(console.log);
