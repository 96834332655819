import React from 'react';
import { Button, Col, Colors, Label, Row, Spacer, TextInput, Tooltip } from '@gower/react-ui/components';
import { AiOutlineDelete, MdHelpOutline } from '@gower/react-ui/icons';
import { useStore } from '../../store';
import { useStatus } from '@gower/react-ui/hooks';

const SERVICE_TOOLTIP = "Recuerda ingresar códigos existentes en el nomenclador del convenio seleccionado";

export type ServicesProps = {

};
export const Services: React.FC<ServicesProps> = ( { } ) => {

    const store = useStore();
    const serviceInputRef = React.useRef<HTMLInputElement>();
    const inputStatus = useStatus( 'ok' );
    const [code, setCode] = React.useState( '' );
    const [quantity, setQuantity] = React.useState( '1' );
    const readOnly = !store.canAddServices;
    const loading = inputStatus.isLoading;

    async function submit () {
        inputStatus.setLoading();
        if ( !quantity || Number( quantity ) > 0 ) {
            await store.addService( code, Number( quantity || 1 ) );
            clear();
        }
        inputStatus.setOk();
        serviceInputRef.current?.focus();
    }

    function clear () {
        setCode( '' );
        setQuantity( '1' );
        serviceInputRef.current?.focus();
    }

    React.useEffect( () => {
        const listener = () => { clear(); };
        store.on( 'reset', listener );
        return () => { store.removeListener( 'reset', listener ); };
    }, [] );

    return <>
        <Col cols={12} color="surface-0" rounded>
            <Row>
                <Col
                    cols={12}
                    paddingX={20}
                    paddingY={10}
                >
                    <Row noWrap>
                        <Col gutter center>
                            <Label bold xxsmall>Prestaciones</Label>
                        </Col>
                        <Spacer />
                    </Row>
                </Col>
                <Col cols={12} paddingX={20}>
                    <Row>
                        <Col cols={12} gutter center >
                            <TextInput
                                prefix='Prestación:'
                                placeholder="# # # # # #"
                                selectAllOnFocus
                                numeric
                                noDelayed
                                large
                                value={code}
                                onChangeText={setCode}
                                loading={loading}
                                readOnly={readOnly}
                                onEnterUp={submit}
                                onEscapeUp={clear}
                                contentRight={
                                    !store.Status.isLoading &&
                                    <Col center paddingX={5} paddingTop={5}>
                                        <Tooltip title={SERVICE_TOOLTIP}>
                                            <MdHelpOutline size={20} color={Colors['primary']} />
                                        </Tooltip>
                                    </Col>
                                }
                            />
                        </Col>
                        <Col cols={8} gutter center >
                            <TextInput
                                prefix='Cantidad:'
                                placeholder="# # #"
                                selectAllOnFocus
                                numeric
                                large
                                noDelayed
                                value={quantity}
                                onChangeText={setQuantity}
                                onEnterUp={submit}
                                onEscapeUp={clear}
                                disabled={readOnly}
                            />
                        </Col>
                        <Col cols={4} gutter center>
                            <Button
                                primary
                                bold
                                block
                                normal
                                label="Agregar"
                                onClick={submit}
                                disabled={!code || !Number( quantity ) || readOnly}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>


        <Col cols={12} />
        <Col cols={12}
            white
            start
            scrollOnlyVertical
            paddingY={10}
            minHeight={150}
            disabled={readOnly}
        >
            {store.Request.items.filter( x => x.isValid ).map( ( item, key ) => (
                <Row key={key} start>
                    <Col gutter center>
                        <Label label={item.Service?.Id} />
                    </Col>
                    <Spacer gutter center>
                        <Label gutter label={item.Service?.Name} />
                    </Spacer>
                    <Col gutter center>
                        <Label label={'Cant. ' + String( item.Quantity )} />
                    </Col>
                    <Col gutter center>
                        <Button
                            normalTheme
                            fontSize={18}
                            icon={AiOutlineDelete}
                            onClick={() => store.removeService( item )}
                        />
                    </Col>
                </Row>
            ) )}
        </Col>
    </>;
};
