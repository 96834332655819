import React from 'react';
import { Button, Col, Colors, Label, Row, Spacer, TextInput, Tooltip } from '@gower/react-ui/components';
import { MdHelpOutline } from '@gower/react-ui/icons';
import { useStore } from '../../store';
import { Grid } from '../grid';
import { VerifyServicesModal } from 'lib/components/verify-services';
import { InputPreAuthorizationCode } from './InputPreauthorizationCode';
import { useBoolean, useStatus } from '@gower/react-ui/hooks';
import { InputDelegation } from './InputDelegation';

const SERVICE_TOOLTIP = "Recuerda ingresar códigos existentes en el nomenclador del convenio seleccionado";

export type ServicesProps = {

};
export const Services: React.FC<ServicesProps> = ( { } ) => {

    const store = useStore();
    const serviceInputRef = React.useRef<HTMLInputElement>();
    const inputStatus = useStatus( 'ok' );
    const showVerifyService = useBoolean( false );
    const [code, setCode] = React.useState( '' );
    const [quantity, setQuantity] = React.useState( '1' );
    const readOnly = !store.canAddServices || !store.Status.isOk;
    const loading = inputStatus.isLoading;

    async function submit () {
        inputStatus.setLoading();
        if ( code && ( !quantity || Number( quantity ) > 0 ) ) {
            await store.addService( code, Number( quantity || 1 ) );
            clear();
        }
        inputStatus.setOk();
        if ( serviceInputRef.current ) {
            serviceInputRef.current.value = '';
            serviceInputRef.current.focus();
        }
    }

    function clear () {
        setCode( '' );
        setQuantity( '1' );
    }

    React.useEffect( () => {
        const listener = () => { clear(); };
        store.on( 'reset', listener );
        return () => { store.removeListener( 'reset', listener ); };
    }, [] );

    return <>

        <Row
            columns
            color="surface-1"
            borderRadius={5}
            minHeight={200}
        >
            <Col>
                <Row columns padding={8}>
                    <Col>
                        <Row noWrap height={50} noSelect>
                            <Col gutter center>
                                <Label bold xxsmall>Prestaciones</Label>
                            </Col>
                            <Spacer />
                            <Col gutter center>
                                <Button
                                    pointer
                                    disabled={loading || !store.canFindServices}
                                    xsmall
                                    label="Buscador de prestaciones"
                                    onClick={() => showVerifyService.enable()}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row rows >
                            <Col cols={4} minWidth={270} paddingTop={4} horizontalGutter show={store.Config?.NeedDelegation === true}>
                                <InputDelegation />
                            </Col>
                            <Col cols={4} minWidth={270} paddingTop={4} horizontalGutter show={store.Config?.NeedPreauthorizationCode === true}>
                                <InputPreAuthorizationCode />
                            </Col>
                        </Row>
                        <Row rows >
                            <Col cols={4} minWidth={270} paddingTop={4} horizontalGutter>
                                <TextInput
                                    ref={serviceInputRef}
                                    prefix='Prestación:'
                                    placeholder="# # # # # #"
                                    selectAllOnFocus
                                    numeric
                                    noDelayed
                                    large
                                    value={code}
                                    onChangeText={setCode}
                                    loading={loading}
                                    readOnly={readOnly}
                                    onEnterUp={submit}
                                    onEscapeUp={clear}
                                    contentRight={
                                        !loading &&
                                        <Col center paddingX={5} paddingTop={5}>
                                            <Tooltip title={SERVICE_TOOLTIP}>
                                                <MdHelpOutline size={20} color={Colors['primary']} />
                                            </Tooltip>
                                        </Col>
                                    }
                                />
                            </Col>
                            <Col cols={4} minWidth={250} paddingTop={4}>
                                <Row noWrap>
                                    <Col cols={2} minWidth={140} horizontalGutter>
                                        <TextInput
                                            prefix='Cantidad:'
                                            placeholder="# # #"
                                            selectAllOnFocus
                                            numeric
                                            large
                                            noDelayed
                                            value={quantity}
                                            onChangeText={setQuantity}
                                            onEnterUp={submit}
                                            onEscapeUp={clear}
                                            disabled={readOnly}
                                        />
                                    </Col>
                                    <Col cols={1} minWidth={100} center horizontalGutter>
                                        <Button
                                            primary
                                            padding={0}
                                            block
                                            buttonLarge
                                            label="Agregar"
                                            onClick={submit}
                                            disabled={!code || !Number( quantity ) || readOnly}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col
                scrollOnlyVertical
                minHeight={150}
                maxHeight={300}
                disabled={readOnly}
            >
                <Grid Store={store} />
                <Row justifyCenter padding={4} noSelect show={!store.TotalItems}>
                    <Col>
                        <Label xxsmall textGrey>Ingresá una prestación para continuar</Label>
                    </Col>
                </Row>
            </Col>
        </Row>
        <VerifyServicesModal
            affiliateId={store.Affiliate?.Id}
            affiliatePlan={store.Affiliate?.Plan?.Id}
            agreement={store.Agreement?.Id}
            isOpen={showVerifyService.Actived}
            onClose={() => showVerifyService.disable()}
            onSelect={( item ) => store.addService( item?.Id )}
            canSelect={store.canAddServices}
        />
    </>;
};
