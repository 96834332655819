import React from 'react';
import { ErrorManager } from '@gower/react-ui/utils';
import { News } from '@gower/cm-mdp-sdk/news';
import { Content, Row, Col, TextInput, Label, Div, Spacer, Button, Divider, Code, Chip, Autocomplete, Colors } from '@gower/react-ui/components';
import { MessageBox } from '@gower/react-ui/message-box';
import { RichEditor, convertToHTML, EditorState } from '@gower/react-ui/template/rich-editor';
import { HtmlEditor, HtmlEditorRef } from '@gower/react-shared-ui/template/html-editor';
import { Form, FormHeader, FormInput } from '@gower/react-ui/template/form';
import { Notifications } from '@gower/react-ui/notifications';
import { expose, ViewStatus } from '@gower/react-ui/store';
import { Wait } from '@gower/utils';
import { NewSubStore } from '../../store';
import { Authorization } from '@gower/cm-mdp-sdk/authorizations';
import { NewsProvider } from 'app/news-provider';
import { NewsColors, NewsItem, NewsIcons } from 'lib/components/news-container';
import { ButtonGroup, ButtonGroupItem } from '@gower/react-ui/template/button-group';
import { AiOutlineClose } from '@gower/react-ui/icons';
import { NewsHtmlEditor, NewsHtmlEditorRef } from './news-html-editor';


class NewsCreateStore extends NewSubStore {

    public Status = ViewStatus.new( 'pending' );
    public EditItem = new News();
    public ErrorManager = ErrorManager.new( {
        fields: ['title', 'type', 'agreement', 'message']
    } );

    onAfterRender () {
        this.Status.setPending();
        this.EditItem = new News();
        if ( this.EditItem )
            this.Status.setOk();
    }

    public checkErrors () {

        this.ErrorManager.clear();
        if ( !this.EditItem.Title ) this.ErrorManager.setTitle( 'Completá este campo' );
        if ( !this.EditItem.Type || this.EditItem.Type.isUnspecified ) this.ErrorManager.setType( 'Completá este campo' );
        if ( this.EditItem.Type.isAgreement && ( !this.EditItem.Agreement || this.EditItem.Type.isUnspecified ) ) this.ErrorManager.setAgreement( 'Completá este campo' );
        if ( !this.EditItem.Message || this.EditItem.Message.length < 4 ) this.ErrorManager.setMessage( 'Completá este campo' );

        return !this.ErrorManager.hasErrors();
    }

    async save () {

        if ( this.checkErrors() ) {
            this.Status.setSaving();

            const result = await this.EditItem.save();
            if ( result.result ) {

                Notifications.Saved();
                await Wait( 1000 );
                await NewsProvider.activeForcedReload();
                this.MainStore.list( this.EditItem );

            } else {
                await MessageBox.Error( result.errorList );
            }

            this.Status.setOk();
        }
    }

    async close () {
        this.MainStore.list();
    }
}

const { provider, useStore } = expose( NewsCreateStore );

export const NewsCreate = provider( () => {

    const store = useStore();
    const editorRef = React.useRef<NewsHtmlEditorRef>();
    const { EditItem } = store;

    function save () {
        const html = editorRef.current?.getHTML() || '';
        store.EditItem.Message = html;
        store.save();
    }

    return <>
        <Content columns scrollOnlyVertical>
            <FormHeader
                title="Editar noticia"
                subTitle={store.Selected?.Title}
            >
                <Col gutter center>
                    <Button
                        actionTheme
                        disabled={!store.Status.isOk}
                        label="Cancelar"
                        onClick={() => store.close()}
                    />
                </Col>
                <Col gutter center>
                    <Button
                        primary
                        disabled={store.Status.isLoading}
                        loading={store.Status.isSaving}
                        onClick={() => save()}
                        label="Guardar"
                    />
                </Col>
            </FormHeader>
            <Form>
                <FormInput
                    title="Titulo"
                    subTitle="Titulo de la noticia"
                >
                    <TextInput
                        filled
                        value={EditItem.Title}
                        onChangeText={t => EditItem.Title = t}
                        placeholder="Titulo"
                        readOnly={!store.Status.isOk}
                        hint="Minimo 4 carácteres"
                        error={store.ErrorManager.getTitle()}
                    />
                </FormInput>

                <FormInput
                    title="Estado"
                    subTitle="Estado de la noticia"
                >
                    <Autocomplete
                        filled
                        placeholder="Estado de la noticia"
                        options={News.VO.Status.getOptions()}
                        value={store.EditItem.Status}
                        onChange={( e ) => store.EditItem.Status = e.target.value}
                        readOnly={!store.Status.isOk}
                    />
                </FormInput>

                <FormInput
                    title="Tipo"
                    subTitle="Tipo de la noticia"
                >
                    <Autocomplete
                        filled
                        placeholder="Tipo de noticia"
                        options={News.VO.Type.getOptions()}
                        value={store.EditItem.Type}
                        onChange={( e ) => store.EditItem.Type = e.target.value}
                        readOnly={!store.Status.isOk}
                        errorMessage={store.ErrorManager.getType()}
                    />
                </FormInput>

                <FormInput
                    title="Convenio"
                    subTitle="Convenio de la noticia"
                    show={store.EditItem.Type.isAgreement}
                >
                    <Autocomplete
                        filled
                        placeholder="Convenio"
                        options={Authorization.VO.Agreement.getOptions()}
                        value={store.EditItem.Agreement}
                        onChange={( e ) => store.EditItem.Agreement = e.target.value}
                        readOnly={!store.Status.isOk}
                        errorMessage={store.ErrorManager.getAgreement()}
                    />
                </FormInput>

                <FormInput
                    title="Icono"
                    subTitle="Icono de la noticia"
                >
                    <Row rows noWrap>
                        {NewsIcons.map( ( item, idx ) => {

                            const selected = store.EditItem.Icon === item.type;

                            return <>
                                <Col gutter key={idx}>
                                    <Button
                                        rounded
                                        height="auto"
                                        fontSize={26}
                                        icon={item.Icon}
                                        color={selected ? "surface-7" : undefined}
                                        onClick={() => store.EditItem.Icon = item.type}
                                    >
                                        <Row justifyCenter rounded size={35} >
                                            <Col center>
                                                {item.type === 'none' && <AiOutlineClose size={26} color={selected ? Colors['white'] : Colors['surface-7']} />}
                                                {item.Icon && <item.Icon size={26} color={selected ? Colors['white'] : Colors['surface-7']} />}
                                            </Col>
                                        </Row>
                                    </Button>
                                </Col>
                            </>;
                        } )}
                    </Row>
                </FormInput>

                <FormInput
                    title="Color"
                    subTitle="Color de la noticia"
                >
                    <Row rows noWrap>
                        {NewsColors.map( ( item, idx ) => <>
                            <Col gutter key={idx}>
                                <Button
                                    rounded
                                    height="auto"
                                    paddingX={2}
                                    paddingY={4}
                                    color={store.EditItem.Color === item.type ? "surface-6" : undefined}
                                    onClick={() => store.EditItem.Color = item.type}
                                >
                                    <Row justifyCenter backgroundColorCss={item.color as any} rounded size={35} >
                                        <Col center>
                                            {item.type === 'none' && <AiOutlineClose size={26} color={store.EditItem.Color === item.type ? Colors['white'] : Colors['surface-7']} />}
                                        </Col>
                                    </Row>
                                </Button>
                            </Col>
                        </> )}
                    </Row>
                </FormInput>

                <NewsHtmlEditor
                    item={store.EditItem}
                    ref={editorRef}
                />

            </Form>
        </Content>
    </>;

} );