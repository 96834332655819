import React from 'react';
import { Button, Chip, Col, Colors, Content, Div, Label, Row, SearchInput, Skeleton, Spacer, Tooltip } from '@gower/react-ui/components';
import { RoutesEnum } from 'app/routes';
import { AgreementLogo } from 'lib/components/agreement-logo';
import { Guid } from '@gower/utils';
import { useNews } from 'features/hooks/useNews';
import { NewsContainer } from 'lib/components/news-container';
import { IoMdNotifications } from '@gower/react-ui/icons';
import { useStore, provider } from './store';
import { AgreementConfig } from '@gower/cm-mdp-sdk/agreement-config';
import { Loader } from '@gower/react-ui/template/loader';
import { AppLogo } from 'lib/components/logo';
import { useConfiguration } from 'features/hooks/useConfig';

export const HomeView = provider( () => {

    return (
        <HomeTemplate />
    );

}, { viewName: 'HomeView' } );

export const HomeTemplate = () => {

    const store = useStore();

    const [searchText, setSearchText] = React.useState( '' );
    const [items, setItems] = React.useState<AgreementConfig[]>( [] );
    const news = useNews();
    const config = useConfiguration();

    React.useEffect( () => {
        news.refresh();
    }, [] );

    React.useEffect( () => {

        if ( searchText ) {
            const text = searchText.toLowerCase();
            return setItems( store.Agreements.filter( item => item.Agreement.Name.toLowerCase().includes( text ) || item.Agreement.Description.toLowerCase().includes( text ) ) );
        }
        else {
            setItems( store.Agreements );
        }
    }, [searchText] );

    return <>
        <Content
            start
            justifyCenter
            scroll
        >

            <Col
                cols={12}
                maxWidth={1200}
                paddingTop={20}
                paddingX={5}
                paddingBottom={100}
            >
                <NewsContainer news={news.generalNews} />

                <Row columns start>
                    <Col paddingX={5}>
                        <Row noWrap paddingY={10} paddingX={20} white boxShadow="box-shadow-2" justifyEnd>
                            <Spacer center>
                                <Label textGrey xxsmall noSelect label="Seleccione el convenio a autorizar" />
                            </Spacer>
                            <Col center>
                                <Row justifyEnd>
                                    <Col center gutter show={store.Status.isOk}>
                                        <Chip small py-1 px-2 color="primary">
                                            {store.Agreements.filter( item => !item.Deactivated ).length} disponibles
                                        </Chip>
                                    </Col>
                                    <Col center gutter mobileHidden>
                                        <SearchInput
                                            readOnly={!store.Status.isOk}
                                            placeholder='Buscar un convenio'
                                            value={searchText}
                                            clearable
                                            onSearch={( t ) => setSearchText( t )}
                                            onClear={() => setSearchText( '' )}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col paddingTop={10}>
                        <Row fullWidth rows start minHeight={300}>
                            <Loader
                                status={store.Status}
                                retryOnErrorFn={() => store.find()}
                                loadingFullContent={
                                    <Row fullWidth rows start minHeight={300}>
                                        {new Array( 12 ).fill( 0 ).map( ( item, idx ) => <EntityLoader key={idx} /> )}
                                    </Row>
                                }
                            >
                                {store.Status.isOk && items.filter( item => !item.Status.isHidden ).map( ( item, idx ) => <EntityItem key={item.Agreement.Id} item={item} last={( idx + 1 ) === items.length} /> )}
                                {(config.config?.data?.unregistered_prescriptions?.enabled && store.allowPrescriptions) && <PrescriptionItem />}
                            </Loader>
                        </Row>
                    </Col>

                </Row>
            </Col>

        </Content>




    </>;
};

const EntityLoader = () => {

    return <>
        <Col
            mobile={12}
            cols={4}
            padding={4}
        >
            <Skeleton
                height={133}
                skeletonHeight={180}
            />
        </Col>
    </>;
};

type EntityItemProps = {
    item: AgreementConfig;
    last: boolean;
};
const EntityItem: React.FC<EntityItemProps> = ( { item, last } ) => {

    const news = useNews();
    const link = `${ RoutesEnum.AuthorizeForm }/${ item.Agreement.Id }?g=${ Guid( 1, true ) }`;

    return <>
        <Col
            mobile={12}
            cols={4}
            padding={4}
        >
            <Button
                block
                noSelect
                disabled={item.Status.isDeactivated}
                noneTheme
                fullWidth
                height="auto"
                padding={0}
                boxShadow="box-shadow-2"
                reactLink={link}
                white
                pointer
                align="flex-start"
                justify="none"
                hoverColor='surface-2'
                borderBottom={!last}
                borderColor='surface-4'

            >
                <Content columns start padding={20}>
                    <Col paddingBottom={15}>
                        <Row start minHeight={40}>
                            <Col justifyStart center paddingRight={10}>
                                <AgreementLogo agreement={item.Agreement.Id} width="60px" height="35px" />
                            </Col>
                            <Spacer center>
                                <Row justifyCenter>
                                    <Col center>
                                        <Label uppercase xxnormal textWeight='500'>{item.Agreement.Description}</Label>
                                    </Col>
                                </Row>
                            </Spacer>
                            <Col justifyStart center paddingRight={10} width={30}>
                                {( !item.Status.isDeactivated && news.getAgreementNews( item.Agreement.Id ).length > 0 ) && <>
                                    <Tooltip title="Convenio con noticias disponibles.">
                                        <IoMdNotifications size={22} color={Colors['orange-4']} />
                                    </Tooltip>
                                </>}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col cols={12}>
                                <Label p uppercase textGrey textLeft xsmall>{item.Agreement.Name}</Label>
                            </Col>
                        </Row>
                    </Col>
                </Content>
            </Button>
        </Col>
    </>;
};

const PrescriptionItem: React.FC = ( {  } ) => {

    const link = `${ RoutesEnum.UnregisteredElectronicPrescriptions }?g=${ Guid( 1, true ) }`;

    return <>
        <Col
            mobile={12}
            cols={4}
            padding={4}
        >
            <Button
                block
                noSelect
                noneTheme
                fullWidth
                padding={0}
                height="auto"
                boxShadow="box-shadow-2"
                reactLink={link}
                pointer
                align="flex-start"
                justify="none"
                textColor="black"
                hoverColor='surface-2'
                borderColor='surface-4'
                color="surface-3"
            >
                <Content columns start padding={20}>
                    <Col paddingBottom={15}>
                        <Row start minHeight={40}>
                            <Col justifyStart center paddingRight={10}>
                                <AppLogo color="surface-7" size={30} />
                            </Col>
                            <Spacer center>
                                <Row justifyCenter>
                                    <Col center>
                                        <Label uppercase xxnormal textWeight='500'>Receta Electrónica</Label>
                                    </Col>
                                </Row>
                            </Spacer>
                            <Col justifyStart center paddingRight={10} width={30}>
                                {/* {( !item.Status.isDeactivated && news.getAgreementNews( item.Agreement.Id ).length > 0 ) && <>
                                    <Tooltip title="Convenio con noticias disponibles.">
                                        <IoMdNotifications size={22} color={Colors['orange-4']} />
                                    </Tooltip>
                                </>} */}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col cols={12}>
                                <Label p uppercase textGrey textLeft xsmall>Resto de convenios - Centro Médico de Mar del Plata</Label>
                            </Col>
                        </Row>
                    </Col>
                </Content>
            </Button>
        </Col>
    </>;
};
