import { News, VO } from '@gower/cm-mdp-sdk/news';
import styles from './news-container.module.scss';
import { Col, Row, Spacer, Label, Colors } from '@gower/react-ui/components';
import { AiOutlineClockCircle } from '@gower/react-ui/icons';
import { AiOutlineBell, AiOutlineClose, AiOutlineComment, AiOutlineEnvironment, AiOutlineExclamationCircle, AiOutlineQuestionCircle, TbNews } from '@gower/react-ui/icons';

export type NewsIcon = {
    type: VO.NewsIconsType,
    Icon: React.FC<any>;
};
export const NewsIcons: NewsIcon[] = [
    { type: 'none', Icon: null },
    { type: 'bell', Icon: AiOutlineBell },
    { type: 'comment', Icon: AiOutlineComment },
    //{ type: 'environment', Icon: AiOutlineEnvironment },
    { type: 'exclamation', Icon: AiOutlineExclamationCircle },
    { type: 'question', Icon: AiOutlineQuestionCircle },
    { type: 'news', Icon: TbNews },
];

export type NewsColor = {
    type: VO.NewsColorsType,
    color: string;
    Icon?: React.FC<any>;
};
export const NewsColors: NewsColor[] = [
    { type: 'none', color: Colors['transparent'] },
    { type: 'red', color: Colors['red-3'] },
    { type: 'green', color: Colors['green-3'] },
    { type: 'blue', color: Colors['blue-3'] },
    { type: 'surface', color: Colors['surface-5'] },
];

export type NewsContainerProps = {
    news: News[];
};
export const NewsContainer: React.FC<NewsContainerProps> = ( { news } ) => {
    return <>
        <Row columns paddingY={10}>
            {news.map( ( item ) => (
                <Col paddingBottom={2} paddingX={5} key={item.Id}>
                    <NewsItem item={item} />
                </Col>
            ) )}
        </Row>
    </>;
};

export type NewsItemProps = {
    item: News;
};
export const NewsItem: React.FC<NewsItemProps> = ( { item } ) => {

    const Icon = NewsIcons.find( e => e.type === item.Icon )?.Icon;
    const ColorConfig = NewsColors.find( e => e.type === item.Color );

    if ( ColorConfig && ColorConfig?.type !== 'none' ) {

        const color = ColorConfig.color;

        return <>
            <Row stretch>
                <Col stretch style={{ alignContent: 'center', justifyContent: 'center' }} padding={10} backgroundColorCss={color} width={60}>
                    {Icon && <Icon size={40} color={Colors['white']} />}
                </Col>
                <Spacer borderTop={4} borderColorCss={color}>
                    <Row
                        columns
                        noWrap
                        padding={8}
                        borderRadius={4}
                        color="white">
                        <Col>
                            <Row noWrap noSelect>
                                <Spacer center gutter>
                                    <Label xxsmall bold label={item.Title} />
                                </Spacer>
                                <Col center gutter>
                                    <AiOutlineClockCircle size={14} />
                                </Col>
                                <Col center gutter>
                                    {item.Date && <Label xsmall textGrey lineHeight={0} label={item.Date.subtract( 3, 'hours' ).format( 'DD-MM-YYYY' )} />}
                                </Col>
                            </Row>
                        </Col>
                        <Col padding={10} className={styles['news-container']}>
                            <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: item.Message }} />
                        </Col>
                    </Row>
                </Spacer>
            </Row>
        </>;
    }
};