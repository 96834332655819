import React from 'react';
import { News } from '@gower/cm-mdp-sdk/news';
import { Row, Col } from '@gower/react-ui/components';
import { FormInput } from '@gower/react-ui/template/form';
import { NewsItem } from 'lib/components/news-container';
import { ButtonGroup, ButtonGroupItem } from '@gower/react-ui/template/button-group';
import { HtmlEditor, HtmlEditorRef } from '@gower/react-shared-ui/template/html-editor';
import { useForward } from '@gower/react-ui/hooks';

export type NewsHtmlEditorRef = {
    getHTML: () => string;
};

export type NewsHtmlEditorProps = {
    item: News;
};

export const NewsHtmlEditor = React.forwardRef<NewsHtmlEditorRef, NewsHtmlEditorProps>( ( { item }, ref ) => {

    const [html, setHtml] = React.useState( item.Message );
    const editorRef = React.useRef<HtmlEditorRef>();
    const [mode, setMode] = React.useState<'editor' | 'preview'>( 'editor' );
    useForward<NewsHtmlEditorRef>( ref, {
        getHTML: () => editorRef.current?.getHTML()
    } );

    function updateHtml () {
        const html = editorRef.current?.getHTML() || '';
        setHtml( html );
        return html;
    }

    function go ( mode: 'editor' | 'preview' ) {
        if ( mode === 'preview' ) {
            item.Message = updateHtml();
        }
        setMode( mode );
    }

    React.useEffect( () => {
        if ( item.Message !== html )
            setHtml( item.Message );
    }, [item.Message] );

    return ( <>
        <FormInput
            title="Cuerpo"
            subTitle="Contenido de la noticia"
            expand
        >
            <ButtonGroup>
                <ButtonGroupItem onClick={() => go( 'editor' )} active={mode === 'editor'} label="Editor" />
                <ButtonGroupItem onClick={() => go( 'preview' )} active={mode === 'preview'} label="Previsualizar" />
            </ButtonGroup>
        </FormInput>
        <Row>
            <Col gutter cols={12} display={mode === 'editor' ? 'flex' : 'none'}>
                <HtmlEditor
                    width="100%"
                    minHeight={300}
                    ref={editorRef}
                    value={html}
                />
            </Col>
            <Col gutter cols={12} minHeight={300} show={mode === 'preview'}>
                <NewsItem item={item} />
            </Col>
        </Row>
    </> );
} );