import { Col, GowerName, Spacer, Panel, Button, Label, Div, Circle, Row, Content, Title, Tooltip } from '@gower/react-ui/components';
import { AppLogo, AppName } from 'lib/components/logo';
import { initials } from '@gower/utils';
import React from 'react';
import { AiOutlineLogout, AiOutlineUser, AiOutlineBook, BiError, FiAlertCircle, IoMdHelpCircleOutline } from '@gower/react-ui/icons';
import { DrawerWrapper } from '@gower/react-ui/template/drawer';
import { useSession } from 'features/hooks/useSession';
import { useLogout } from 'features/hooks/useLogout';
import { DrawerItems, RoutesEnum } from 'app/routes';
import { useBoolean } from '@gower/react-ui/hooks';
import { QuickResponseModal } from 'lib/components/quick-response';

export type HeaderProps = {

};
export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ( { children } ) => {
    const { user } = useSession();
    const { logout } = useLogout();
    const quickResponseModal = useBoolean( false );

    return <>
        <Content color='white' box-shadow-1 height={64} noWrap zIndex={10}>
            <Col>
                <DrawerWrapper
                    items={DrawerItems}
                    logo={
                        <Row justifyCenter fullWidth noWrap noScroll marginLeft={-8}>
                            <Col center pr-1 >
                                <AppLogo color="surface-7" size={30} />
                            </Col>
                            <Col center paddingTop={8}>
                                <Div block textLeft>
                                    <AppName color="black" width={155} />
                                    <Title xsmall lineHeight={1.55} light letterSpacing="wide" label="Centro de Autorizaciones" />
                                </Div>
                            </Col >
                        </Row>
                    }
                    drawerFooter={
                        <Row paddingX={20} paddingY={5}>
                            <Col >
                                <Div block small textGrey>
                                    <Label p>Centro de autorizaciones</Label>
                                    {window.env?.REACT_APP_STAGE === 'pub' && <>
                                        <Label p>v{window.env?.REACT_APP_VERSION}</Label>
                                    </>}
                                    {window.env?.REACT_APP_STAGE !== 'pub' && <>
                                        <Label p>v{window.env?.REACT_APP_VERSION} ({window.env?.REACT_APP_STAGE})</Label>
                                    </>}
                                </Div>
                            </Col>
                        </Row>
                    }
                />
            </Col>
            <Col center px-2>
                <Button pa-1 height="auto" borderRadiusAll={5} noneTheme text reactLink='/'>
                    <Row justifyCenter fullWidth noWrap noScroll>
                        <Col center pr-2 >
                            <AppLogo color="surface-7" size={30} />
                        </Col>
                        <Col center >
                            <Div block textLeft>
                                <AppName color="black" width={180} />
                                <Title xsmall ma-0 light letterSpacing="wide" label="Centro de Autorizaciones" />
                            </Div>
                        </Col >
                        <Col center paddingX={10} mobileHidden>
                            {window.env?.REACT_APP_STAGE !== 'pub' && <>
                                <Label p large textRed>Entorno de prueba ({window.env?.REACT_APP_STAGE})</Label>
                            </>}
                        </Col >
                    </Row>
                </Button>
            </Col>
            <Spacer noScroll height="100%" px-1>
                {children}
            </Spacer>

            <Col>
                <Row noWrap
                    xlHidden
                    lgHidden
                    mobileHidden
                >
                    <Col center gutter>
                        <Tooltip title="Contingencia">
                            <Button
                                pointer
                                noneTheme
                                text
                                normal
                                textColor="orange-6"
                                reactLink={RoutesEnum.Contingency}
                                icon={<BiError size={22} />}
                            />
                        </Tooltip>
                    </Col>
                    <Col center gutter>
                        <Tooltip title="Asistencia rápida">
                            <Button
                                pointer
                                noneTheme
                                text
                                normal
                                onClick={quickResponseModal.enable}
                                icon={<FiAlertCircle size={22} />}
                            />
                        </Tooltip>
                    </Col>
                    <Col center gutter>
                        <Tooltip title="Instructivo">
                            <Button
                                pointer
                                noneTheme
                                text
                                normal
                                //reactLink={RoutesEnum.Manual}
                                onClick={() => window.open( '/assets/manual.pdf' )}
                                icon={<AiOutlineBook size={22} />}
                            />
                        </Tooltip>
                    </Col>
                    <Col center gutter>
                        <Tooltip title="Ayuda">
                            <Button
                                pointer
                                noneTheme
                                text
                                normal
                                //reactLink={RoutesEnum.FAQ}
                                onClick={() => window.open( '/assets/faq.pdf' )}
                                icon={<IoMdHelpCircleOutline size={22} />}
                            />
                        </Tooltip>
                    </Col>

                </Row>

                <Row noWrap
                    mdHidden
                    smHidden
                    mobileHidden
                    xsHidden
                >
                    <Col center gutter>
                        <Button
                            label="Contingencia"
                            pointer
                            noneTheme
                            text
                            normal
                            textColor="orange-6"
                            reactLink={RoutesEnum.Contingency}
                            icon={<BiError size={22} />}
                        />
                    </Col>
                    <Col center gutter>
                        <Button
                            label="Asistencia rápida"
                            pointer
                            noneTheme
                            text
                            normal
                            textColor="blue-7"
                            onClick={quickResponseModal.enable}
                            icon={<FiAlertCircle size={22} />}
                        />
                    </Col>
                    <Col center gutter>
                        <Button
                            label="Instructivo"
                            pointer
                            noneTheme
                            text
                            normal
                            //reactLink={RoutesEnum.Manual}
                            onClick={() => window.open( '/assets/manual.pdf' )}
                            icon={<AiOutlineBook size={22} />}
                        />
                    </Col>
                    <Col center gutter>
                        <Button
                            label="Ayuda"
                            pointer
                            normal
                            noneTheme
                            text
                            //reactLink={RoutesEnum.FAQ}
                            onClick={() => window.open( '/assets/faq.pdf' )}
                            icon={<IoMdHelpCircleOutline size={22} />}
                        />
                    </Col>

                </Row>
            </Col>

            <Col width="5%" mobileHidden xsHidden />
            <Col fullHeight>
                <ProfileMenu
                    user={user.getJson() as any}
                    logout={logout}
                    myProfileLink={RoutesEnum.Profile}
                />
            </Col>
        </Content>
        <QuickResponseModal isOpen={quickResponseModal.Actived} onClose={quickResponseModal.disable} />
    </>;
};




export type ProfileMenuUserProp = {
    account: number,
    id: string,
    nickname: string,
    name: string,
    lastname: string,
    email: string,
    telephone?: string,
    cellphone?: string,
};

export type ProfileMenuProps = {
    logout?: Function;
    showLogout?: boolean;
    myProfileLink?: string;
    user: ProfileMenuUserProp;
};

export const ProfileMenu: React.FC<React.PropsWithChildren<ProfileMenuProps>> = ( { logout, user, myProfileLink, showLogout = true } ) => {

    const menuEl = React.useRef( null );

    function closeMenu () {
        menuEl.current?.hide();
        return true;
    }

    function openProfile () {
        menuEl.current?.hide();
        window.location.href = myProfileLink;
        return true;
    }

    return <>

        <Button
            padding={0}
            noneTheme
            clip
            height="auto"
            maxWidth={300}
            onClick={( event ) => menuEl.current.toggle( event )}
            pointer
            flat
        >
            <Row noWrap>
                <Col center padding={8}>
                    <Circle
                        label={initials( user.name, user.lastname ).toUpperCase()}
                        xxnormal
                        size={32}
                        xsmall
                    />
                </Col>
                <Col center padding={8} mobileHidden>
                    <Div textLeft block>
                        <Label p xsmall label={`${ user.name || '' } ${ user.lastname || '' }`.trim()} />
                        <Label p xsmall textGrey label={user.email || ''} />
                    </Div>
                </Col>
            </Row>
        </Button>

        <Panel dismissable ref={menuEl}>
            < Row width={350} noSelect justify="space-around">
                <Col cols={12} pa-2>
                    <Row >
                        <Col auto>
                            <GowerName logo logoProps={{ size: 20 }} size={60} />
                        </Col>
                        <Spacer />
                        <Col>
                            {/* <Label pa-0 xsmall label="ir a gowaer.com.ar" hrefTarget="blank" href="https://gower.com.ar" />  */}
                        </Col>
                    </Row>
                </Col>

                <Col pa-2 cols={12} show={true}>
                    <Row>
                        <Col auto gutter center>
                            <Circle label={initials( user.name, user.lastname ).toUpperCase()} xsmall size={32} />
                        </Col>
                        <Spacer gutter center clip>
                            {true && <Div >
                                <Label p xxsmall capitalcase>{`${ user.name || '' } ${ user.lastname || '' }`.trim()}</Label>
                                <Label p xsmall lowercase lh15>{user.email || ''}</Label>
                                <Label p small bold lh15 mt-2>
                                    {/* <Label mr-3 href="/profile">Mi perfil</Label> */}
                                    {/* {Store.App.checkPermisions( 'account_data' ) && <Label bold gutter href="/account">Mi cuenta</Label>} */}
                                </Label>
                            </Div>}

                        </Spacer>
                    </Row>
                </Col>
                <Col cols={5} center gutter>
                    <Button
                        mx-auto
                        show={showLogout}
                        pointer
                        flat
                        block
                        borderRadiusAll={5}
                        onClick={() => closeMenu() && logout()}
                    >
                        <Row justifyCenter>
                            <Col center gutter>
                                <AiOutlineLogout size={16} />
                            </Col>
                            <Col center>
                                <Label bold>Cerrar sesión</Label>
                            </Col>
                        </Row>
                    </Button>
                </Col>
                <Col cols={5} center gutter>
                    <Button
                        block
                        mx-auto
                        hidden={!myProfileLink}
                        pointer
                        flat
                        borderRadiusAll={5}
                        onClick={openProfile}
                    >
                        <Row justifyCenter>
                            <Col center gutter>
                                <AiOutlineUser size={16} />
                            </Col>
                            <Col center>
                                <Label bold>Mi perfil</Label>
                            </Col>
                        </Row>
                    </Button>
                </Col>
                {/* <Col pa-2 cols={12}>
                    <Row>
                        <Col textLeft gutter clip cols={6} xxsmall bold>
                            {Store.App.AppName}
                        </Col>
                        <Col textRight gutter clip cols={6} xxsmall>
                            {Store.App.isAuthenticated && <Label p>Nº de Cuenta {Store.App.Session.Account.padStart( 5, '0' )}</Label>}
                            <Label p>Versión {Store.App.Version}</Label>
                        </Col>
                    </Row>
                </Col> */}
            </Row>
        </Panel >
    </>;
};

